


























































import { Component, Vue } from 'vue-property-decorator';

import { ISiteParserTaskUpdate } from '@/interfaces/site-parser';
import {
dispatchGetSiteParserDomains,
dispatchGetSiteParserTasks,
dispatchUpdateSiteParserTask,
} from '@/store/site-parser/actions';
import { readOneSiteParserTask, readSiteParserDomains } from '@/store/site-parser/getters';
import { domainRules, nameRules } from './validators';

@Component
export default class AvitoEditTask extends Vue {
  public submitting = false;
  public valid = false;
  public name = '';
  public description = '';
  public mode = false;
  public domains: string[]|null = null;
  public nameRules = nameRules;
  public domainRules = domainRules;

  public get domainList() {
    const domains = readSiteParserDomains(this.$store);
    domains.sort();
    return domains;
  }

  public async mounted() {
    this.reset();  // чтобы сразу показать в полях значения, которые уже загружены
    await dispatchGetSiteParserTasks(this.$store);
    await dispatchGetSiteParserDomains(this.$store);
    this.reset();  // обновляем значения полей, если они изменились
  }

  public reset() {
    if (this.task) {
      this.name = this.task.name;
      this.description = this.task.description;
      this.domains = this.task.domains || [];
      this.mode = this.demo;
    } else this.name = this.description = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.submitting = true;
      const updatedTask: ISiteParserTaskUpdate = {
        name: this.name,
        description: this.description || undefined,
        domains: this.domains || [],
        mode: this.mode ? 'demo' : 'prod',
      };
      await dispatchUpdateSiteParserTask(this.$store, { id: this.task!.id, task: updatedTask });
      this.$router.push('/main/site-parser');
    }
  }

  get task() {
    return readOneSiteParserTask(this.$store)(+this.$router.currentRoute.params.id);
  }

  public get isNew() {
    return this.task?.status === 'NEW';
  }

  public get demo() {
    return this.task?.mode === 'demo';
  }
}
